import React, { useState, useEffect } from "react";
import PromotionHeader from "../../../../../components/common/PromotionHeader";
import { connect } from "react-redux";
import { MANAGEMENT_PROMOTION_UPDATE_FIELD } from "../../../../../constants/actionTypes";
import NumberInput from "../../../../../components/common/NumberInput";
import Loader from "../../../../../components/Loader";
import { Countries_Regions } from "../../../../../constants/defaults";

const mapStateToProps = (state) => {
  console.log(state.promotions);
  return {
    promotions: state.promotions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetTier1Price: (price) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "tier1Price",
      value: price,
    });
  },
  onSetTier2Price: (price) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "tier2Price",
      value: price,
    });
  },
  onSetTier3Price: (price) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "tier3Price",
      value: price,
    });
  },
  onSetTier4Price: (price) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "tier4Price",
      value: price,
    });
  },
});

const SetPrice = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [regionCountry, setRegionCountry] = useState(
    Countries_Regions.filter((i, n) => {
      return props.promotions.changePercentage
        .filter((f) => f.weight !== 0)
        .find((o) => o.code === i.alpha2);
    }).reduce((result, currentValue) => {
      (result[currentValue["region"]] =
        result[currentValue["region"]] || []).push(currentValue);
      return result;
    }, {})
  );
  useEffect(() => {
    setLoading(false);
  }, []);

  const keys = Object.keys(regionCountry);
  let percentageSum = props.promotions.changePercentage?.reduce(
    (sum, current) => {
      return sum + parseInt(current.weight);
    },
    0
  );
  percentageSum = percentageSum < 0 ? 0 : percentageSum;
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PromotionHeader
            title={props.title}
            product={props.promotions.selectedProduct?.Name}
            goToSelectGame={() => props.GoToSelectGame()}
          />
          <div className="d-flex flex-row justify-content-center">
            <div className="promotion-percentage-price-box px-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="promotion-country-list-table-header text-center">
                        Cost (€)
                      </th>
                      <th className="promotion-country-list-table-header text-center">
                        Sales Price
                      </th>
                      <th className="promotion-country-list-table-header text-start w-25">
                        Percentage
                      </th>
                    </tr>
                  </thead>
                  {keys.map((key, keyIndex) => {
                    return (
                      <tbody key={keyIndex} className="set-percentages-table">
                        <h5 className="promotion-country-list-region-header ms-5">
                          {key}
                        </h5>
                        {regionCountry[key].map((item, countryKeyIndex) => {
                          return (
                            <tr key={countryKeyIndex}>
                              <td className="w-100">
                                <span
                                  className={
                                    "flag-icon flag-icon-" +
                                    item.alpha2.toLowerCase()
                                  }
                                ></span>
                                <span>{item.name}</span>
                              </td>
                              <td className="text-center">
                                <div>
                                  {Math.round(
                                    props.promotions.selectedRegion.filter(
                                      (m) =>
                                        m.Countries.find(
                                          (f) => f === item.alpha2
                                        )
                                    )[0]?.Cost * 100
                                  ) / 100}
                                </div>
                              </td>
                              <td className="text-center">
                                <div>
                                  {Math.round(
                                    props.promotions.selectedRegion.filter(
                                      (m) =>
                                        m.Countries.find(
                                          (f) => f === item.alpha2
                                        )
                                    )[0]?.Price * 100
                                  ) / 100}
                                </div>
                              </td>
                              <td className="text-center">
                                <div>
                                  {props.promotions.changePercentage?.filter(
                                    (f) => {
                                      return f.code === item.alpha2;
                                    }
                                  )[0]?.weight ?? 0}
                                  %
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    );
                  })}
                </table>
                <div className="percentage-sum">
                  <div className="percentage-sum-p">
                    {"Total: "}
                    <span
                      className={percentageSum > 100 ? "danger" : "success"}
                    >
                      {Number.isNaN(percentageSum) ? 0 : percentageSum}
                    </span>
                    {" / 100"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="promotion-country-list-footer">
            <h5>
              {props.promotions.calculatedAverage?.Cost &&
                `Avg. Cost: ${props.promotions.calculatedAverage?.Cost}`}
            </h5>
            <h5>
              {props.promotions.calculatedAverage?.Price &&
                `Avg. Sales Price: ${props.promotions.calculatedAverage?.Price}`}
            </h5>
          </div>
          <div className="number-inputs-set-price">
            <NumberInput
              centerAlign
              label="Standart"
              value={props.promotions.tier1Price}
              onChange={(ev) => props.onSetTier1Price(ev)}
              visible
            ></NumberInput>
            <NumberInput
              centerAlign
              label="Gold"
              value={props.promotions.tier2Price}
              onChange={(ev) => props.onSetTier2Price(ev)}
              visible
            ></NumberInput>
            <NumberInput
              centerAlign
              label="Platinum"
              value={props.promotions.tier3Price}
              onChange={(ev) => props.onSetTier3Price(ev)}
              visible
            ></NumberInput>
            <NumberInput
              centerAlign
              label="VIP"
              value={props.promotions.tier4Price}
              onChange={(ev) => props.onSetTier4Price(ev)}
              visible
            ></NumberInput>
          </div>
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPrice);
